export const QUERY_KEY_PLP_PAGE_DATA = 'plp-page-data'
export const QUERY_KEY_PLP_COMPARE_ADD_ITEM = 'plp-add-compare-item'

export const QUERY_KEY_PLP_FACET_STORE = 'plp-get-facet-store'
export const QUERY_KEY_PLP_PAGE_INFO = 'plp-page-info'

export const QUERY_KEY_ALGOLIA_PLP_FACET_STORE = 'algolia-plp-get-facet-store'
export const QUERY_KEY_ALGOLIA_PLP_ZIP_CODE_LAT_LNG =
  'algolia-plp-get-zip-code-lat-lng'
export const QUERY_KEY_ALGOLIA_PLP_CITY_STATE = 'algolia-plp-get-city-state'
