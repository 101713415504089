import { FORM_VALIDATE_ERROR, FORM_VALIDATE_SUCCESS } from '@constants/global'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { save } from '@store/header'
import type { RootState } from '@store/index'
import { useStoreInstance } from '@store/index'
import type { ValidateStatus } from 'antd/lib/form/FormItem'
import { isEmpty, some, without } from 'lodash'
import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAlgoliaPLPStoreSearch } from '../algolia-plp/store'
import { useMutationAddHomeStore } from '../global'
import { useUserGeoLocation } from '../user/userLocation'

export const useShowValidateStatus = () => {
  const initialState = {
    validateStatus: '' as ValidateStatus,
    validateHelp: ''
  }
  type TInitialState = typeof initialState
  const reducer = (_state, action): TInitialState => {
    switch (action.type) {
      case FORM_VALIDATE_ERROR:
        return {
          validateStatus: 'error',
          validateHelp: action.payload
        }
      case FORM_VALIDATE_SUCCESS:
        return { validateStatus: '', validateHelp: '' }
      default:
        return { validateStatus: '', validateHelp: '' }
    }
  }
  const [{ validateStatus, validateHelp }, dispatch] = useReducer(
    reducer,
    initialState
  )

  return { dispatch, validateHelp, validateStatus }
}

export const useChangeHeaderBottomActiveArray = () => {
  const store = useStoreInstance()
  const headerBottomActiveArray = useSelector(
    (state: RootState) => state.header.headerBottomActiveArray
  )
  let HBActiveArray = [...headerBottomActiveArray]

  const changeHeaderBottomActiveArray = (type, name) => {
    const hasStatus = isArrayAndNotEmpty(HBActiveArray)
      ? some(HBActiveArray, item => item === name)
      : false

    if (type === 'save') {
      if (!hasStatus) {
        try {
          HBActiveArray = [name, ...headerBottomActiveArray]
        } catch (error) {
          console.log(
            '🚀:26 ~ save name to headerBottomActiveArray  ~ error',
            error
          )
        }
      }
    } else {
      if (hasStatus) {
        try {
          HBActiveArray = without(HBActiveArray, name)
        } catch (error) {
          console.log(
            '🚀:37 ~ delete name from headerBottomActiveArray  ~ error',
            error
          )
        }
      }
    }
    store.dispatch(save({ headerBottomActiveArray: HBActiveArray }))
  }
  return { changeHeaderBottomActiveArray }
}

export const useHeaderGetClosestStoreList = () => {
  const { homeStore = {}, isLoading } = useHeaderUserInfo()
  const storeDefaultConfig = {
    facets: ['*'],
    aroundRadius: 80000,
    getRankingInfo: true,
    hitsPerPage: 1
  }

  const { geoLocation, isUserClickPermission } = useUserGeoLocation()
  const { mutate } = useMutationAddHomeStore()
  const [currentStore, setCurrentStore] = useState<any>()
  const [zipCode, setZipCode] = useState<string>()

  const { latitude, longitude } = geoLocation

  const isNotZipCodeOrLatLon = !latitude && !longitude

  const { data, refetch } = useAlgoliaPLPStoreSearch({
    searchQueries: {
      ...storeDefaultConfig,
      zipCode: !isEmpty(zipCode) ? zipCode : '',
      aroundLatLng: !isEmpty(zipCode) ? '' : `${latitude}, ${longitude}`
    },
    enabled:
      isUserClickPermission &&
      !isLoading &&
      isEmpty(homeStore) &&
      // not zipCode or lat/longitude not invoke
      !isNotZipCodeOrLatLon
  })

  useEffect(() => {
    // headerInfo homeStore => data?.hits?.[0]
    if (!isEmpty(homeStore)) {
      return setCurrentStore(homeStore)
    }

    const storeList = data?.hits?.[0] || {}
    if (!isEmpty(storeList)) {
      mutate(storeList)
      setCurrentStore(storeList)
    }
  }, [data?.hits, homeStore])

  const refetchForZipCode = zipCode => {
    setZipCode(zipCode)
  }
  return {
    currentStoreData: currentStore,
    data: data?.hits?.[0] || {},
    refetch,
    refetchForZipCode
  }
}
