export const getUserLocation = () =>
  new Promise<GeolocationPosition>((resolve, reject) => {
    function onSuccess(position: GeolocationPosition): void {
      resolve(position)
    }

    function onFailure(err: GeolocationPositionError | Error): void {
      reject(err as Error)
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onFailure, {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000
    })
  })
