import { useQuery } from '@tanstack/react-query'

import {
  getBCCMultiMessageContent,
  getBCCMultiNameValueContent
} from '../../api/global'
import {
  messageContentKeys,
  nameValueContentKeys
} from '../../constants/global'
// get content by message

export const useContentByMessage = (
  queryKey: string
): {
  contentItem: Record<string, string>
} => {
  const { data } = useQuery({
    queryKey: [queryKey || 'useContentByMessageDefaultKey'],
    queryFn: () =>
      getBCCMultiMessageContent(messageContentKeys[queryKey] || queryKey),
    enabled: Boolean(queryKey)
  })
  return data || {}
}

// get content by name-value
export const useContentByNameValue = ({
  queryKey,
  keys
}: {
  queryKey: string
  keys?: string
}): any => {
  const customKeys = keys ? keys : nameValueContentKeys[queryKey]
  const { data } = useQuery({
    queryKey: [queryKey || 'useContentByNameValueDefaultKey', customKeys],
    queryFn: () => getBCCMultiNameValueContent(customKeys),
    enabled: Boolean(customKeys)
  })

  return data || {}
}
