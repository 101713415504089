import {
  QUERY_KEY_ALGOLIA_PLP_CITY_STATE,
  QUERY_KEY_ALGOLIA_PLP_FACET_STORE,
  QUERY_KEY_ALGOLIA_PLP_ZIP_CODE_LAT_LNG
} from '@constants/query/plp'
import { thirdPartyService } from '@gc/common-lib/api/request'
import {
  ALGOLIA_CHOOSE_CITY_STATE_EVENT,
  eventEmitter
} from '@gc/common-lib/utils/eventEmitter'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { logger } from '@gc/common-lib/utils/logger'
import { algoliaClient } from '@helper/utils/algoliaClient'
import { getCacheWithExpiry, setCacheWithExpiry } from '@hooks/global'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import type { AlgoliaStoreSearch } from '@typings/algolia-plp'
import { get, isString, omit, size, split, toString } from 'lodash'
import objectHash from 'object-hash'
import { useMemo } from 'react'

interface IStoreSearchProps {
  searchQueries: AlgoliaStoreSearch.IStoreSearchQueries
  setEnableStoreQuery?: (enabled: boolean) => void
  enabled: boolean
}

interface IStoreSearchResult {
  data: AlgoliaStoreSearch.IStoreSearchResponse
  refetch: () => void
  isLoading: boolean
}

export const useAlgoliaPLPStoreSearch = ({
  searchQueries,
  setEnableStoreQuery,
  enabled
}: IStoreSearchProps): IStoreSearchResult => {
  const params = useMemo(
    () => omit(searchQueries, 'zipCode', 'cityState', 'query'),
    [searchQueries]
  )
  const localAlgoliaCityState = getCacheWithExpiry(
    `localAlgoliaCityState-${searchQueries.cityState}`
  )
  const localApacheCityState = getCacheWithExpiry(
    `localApacheCityState-${searchQueries.cityState}`
  )
  const localApacheZipCode = getCacheWithExpiry(
    `localApacheZipCode-${searchQueries.zipCode}`
  )

  /** query "city,state" code start, fetch storeIndex first **/
  let queryAlgoliaCityStateSuccess = false
  /** the below query has a enable flag, while use any "city,state" enable fetch query **/
  const {
    data: findAlgoliaCityState = {},
    isFetching: isAlgoliaCityStateFetching
  } = useQuery<AlgoliaStoreSearch.IStoreSearchResponse>({
    queryKey: [
      QUERY_KEY_ALGOLIA_PLP_CITY_STATE,
      objectHash.sha1({
        ...params,
        query: searchQueries.cityState
      })
    ],
    queryFn: async () => {
      let response
      try {
        response = await (algoliaClient.search([
          {
            indexName: process.env
              .NEXT_PUBLIC_ALGOLIA_STORE_ZIPCODE_INDEX as string,
            params: { ...params, query: searchQueries.cityState }
          }
        ]) as Promise<{
          results: AlgoliaStoreSearch.IStoreSearchResponse[]
        }>)
      } catch (error) {
        logger.info({
          message: 'query algolia store index to get city error',
          error: error
        })
      }
      return response?.results?.[0] || {}
    },
    enabled: Boolean(
      searchQueries.cityState && !localAlgoliaCityState && !localApacheCityState
    )
  })
  if (
    searchQueries.cityState &&
    (localAlgoliaCityState ||
      (!isAlgoliaCityStateFetching &&
        isArrayAndNotEmpty(findAlgoliaCityState?.hits)))
  ) {
    let hits = [] as AlgoliaStoreSearch.IStoreLocation[]
    if (localAlgoliaCityState) {
      queryAlgoliaCityStateSuccess = true
      try {
        hits = JSON.parse(localAlgoliaCityState || '[]')
      } catch (e) {
        logger.warn('Failed to parse local Algolia city state data', e)
        hits = []
      }
    } else if (
      !isAlgoliaCityStateFetching &&
      isArrayAndNotEmpty(findAlgoliaCityState?.hits)
    ) {
      queryAlgoliaCityStateSuccess = true
      hits = findAlgoliaCityState.hits
      setCacheWithExpiry(
        `localAlgoliaCityState-${searchQueries.cityState}`,
        JSON.stringify(hits)
      )
    }
    if (isArrayAndNotEmpty(hits)) {
      if (size(hits) === 1) {
        eventEmitter.emit(ALGOLIA_CHOOSE_CITY_STATE_EVENT, {})
        params.aroundLatLng = `${hits[0]?.cityGeo?.lat || 0}, ${hits[0]?.cityGeo?.lng || 0}`
      } else {
        eventEmitter.emit(ALGOLIA_CHOOSE_CITY_STATE_EVENT, {
          type: 'storeIndex',
          cityStates: hits
        })
      }
    }
  }

  const cityStateFirstLetter = searchQueries.cityState
    ? searchQueries.cityState.toString().slice(0, 1)
    : ''
  /** the below query has a enable flag, while use any "city,state" and storeIndex has no result enable fetch query**/
  const { data: findCityStateLatLng = [], isFetching: isCityStateFetching } =
    useQuery<AlgoliaStoreSearch.ILocation[]>({
      queryKey: [
        QUERY_KEY_ALGOLIA_PLP_ZIP_CODE_LAT_LNG,
        cityStateFirstLetter,
        searchQueries.cityState
      ],
      queryFn: async () => {
        const url =
          process.env.NODE_ENV === 'development'
            ? `/static/ziploc/${cityStateFirstLetter}/${searchQueries.cityState}.json`
            : `${process.env.NEXT_PUBLIC_AJAX_STATIC_ORIGIN}/static/ziploc/${cityStateFirstLetter}/${searchQueries.cityState}.json`

        try {
          const data = await thirdPartyService.get(url)
          return data?.data
        } catch (error) {
          logger.info({
            message: 'query city from apache error',
            error: error
          })
          return []
        }
      },
      enabled: Boolean(
        searchQueries.cityState &&
          !isAlgoliaCityStateFetching &&
          !queryAlgoliaCityStateSuccess &&
          !localAlgoliaCityState &&
          !localApacheCityState
      )
    })

  if (
    searchQueries.cityState &&
    (localApacheCityState ||
      (!isCityStateFetching && !queryAlgoliaCityStateSuccess))
  ) {
    let hits = [] as AlgoliaStoreSearch.ILocation[]
    if (localApacheCityState && isString(localApacheCityState)) {
      try {
        hits = JSON.parse(localApacheCityState || '[]')
      } catch (e) {
        logger.warn('Failed to parse local Apache city state data', e)
        hits = []
      }
    } else if (isArrayAndNotEmpty(findCityStateLatLng)) {
      hits = findCityStateLatLng
      setCacheWithExpiry(
        `localApacheCityState-${searchQueries.cityState}`,
        JSON.stringify(findCityStateLatLng)
      )
    } else {
      eventEmitter.emit(ALGOLIA_CHOOSE_CITY_STATE_EVENT, {})
    }
    if (isArrayAndNotEmpty(hits)) {
      if (size(hits) === 1) {
        eventEmitter.emit(ALGOLIA_CHOOSE_CITY_STATE_EVENT, {})
        params.aroundLatLng = `${hits[0].lat}, ${hits[0].lng}`
      } else {
        eventEmitter.emit(ALGOLIA_CHOOSE_CITY_STATE_EVENT, {
          type: 'file',
          cityStates: hits
        })
      }
    }
  }
  /** query "city,state" code end  **/
  /** query "zipCode" code start  **/
  const formattedZipCode = get(split(toString(searchQueries?.zipCode), '-'), 0)

  const zipCodeFirstThreeLetter = formattedZipCode
    ? formattedZipCode.slice(0, 3)
    : ''

  /** the below query has a enable flag, while use "zipCode' enable fetch the query**/
  const { data: findZipCodeLatLng = [], isFetching: isZipCodeFetching } =
    useQuery<AlgoliaStoreSearch.ILocation[]>({
      queryKey: [
        QUERY_KEY_ALGOLIA_PLP_ZIP_CODE_LAT_LNG,
        zipCodeFirstThreeLetter,
        formattedZipCode
      ],
      queryFn: async () => {
        const url =
          process.env.NODE_ENV === 'development'
            ? `/static/ziploc/${zipCodeFirstThreeLetter}/${formattedZipCode}.json`
            : `${process.env.NEXT_PUBLIC_AJAX_STATIC_ORIGIN}/static/ziploc/${zipCodeFirstThreeLetter}/${formattedZipCode}.json`

        try {
          const data = await thirdPartyService.get(url)
          return data?.data
        } catch (error) {
          logger.info({
            message: 'query zipCode from apache error',
            error: error
          })
          return []
        }
      },
      placeholderData: keepPreviousData,
      enabled: Boolean(formattedZipCode && !localApacheZipCode)
    })
  if (
    formattedZipCode &&
    (localApacheZipCode ||
      (isArrayAndNotEmpty(findZipCodeLatLng) && !isZipCodeFetching))
  ) {
    if (localApacheZipCode && isString(localApacheZipCode)) {
      const hits = JSON.parse(localApacheZipCode || '[]')
      params.aroundLatLng = `${hits[0].lat}, ${hits[0].lng}`
    } else {
      setCacheWithExpiry(
        `localApacheZipCode-${formattedZipCode}`,
        JSON.stringify(findZipCodeLatLng)
      )
      params.aroundLatLng = `${findZipCodeLatLng[0].lat}, ${findZipCodeLatLng[0].lng}`
    }
  }
  /** query "zipCode" code end  **/
  const allUpperFetchDone =
    (formattedZipCode && !isZipCodeFetching) ||
    (searchQueries.cityState &&
      !isAlgoliaCityStateFetching &&
      !isCityStateFetching)
  /** the below storeIndex query has a enable flag, while use LatLng parameter, enable fetch this query,
   * while use "zipCode" or "city,state" parameter, need match more detail to enable fetch this query  **/
  const {
    data = {},
    refetch,
    isFetching
  } = useQuery<IStoreSearchResult>({
    queryKey: [QUERY_KEY_ALGOLIA_PLP_FACET_STORE, objectHash.sha1(params)],
    queryFn: async () => {
      let response
      try {
        response = await (algoliaClient.search([
          {
            indexName: process.env
              .NEXT_PUBLIC_ALGOLIA_STORE_ZIPCODE_INDEX as string,
            params: params
          }
        ]) as Promise<{
          results: AlgoliaStoreSearch.IStoreSearchResponse[]
        }>)
      } catch (error) {
        logger.info({
          message: 'query algolia store index error',
          error: error
        })
      }
      setEnableStoreQuery?.(false)
      return response?.results?.[0] || {}
    },
    enabled: Boolean(
      enabled &&
        ((!formattedZipCode && !searchQueries.cityState) ||
          allUpperFetchDone) &&
        params.aroundLatLng !== ''
    )
  })

  if (enabled && params.aroundLatLng === '' && allUpperFetchDone) {
    setEnableStoreQuery?.(false)
    return {
      data: {} as AlgoliaStoreSearch.IStoreSearchResponse,
      refetch: () => void {},
      isLoading: false
    }
  } else if (
    enabled &&
    searchQueries.cityState === '' &&
    params.aroundLatLng !== '' &&
    !isFetching
  ) {
    setEnableStoreQuery?.(false)
  }

  return {
    data: data as AlgoliaStoreSearch.IStoreSearchResponse,
    refetch,
    isLoading: isFetching
  }
}
