import { GC_GEO_LOCATION_EXPIRED_TIME } from '@gc/common-lib/constants/global'
import { ONE_WEEK } from '@gc/common-lib/constants/time'
import { useContentByNameValue } from '@gc/common-lib/hooks/configurable-content'
import { getUserLocation } from '@gc/common-lib/utils/geoLocation'
import type { RootState } from '@store/index'
import { useStoreInstance } from '@store/index'
import { save as userSave } from '@store/user'
import { isNumber } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const defaultGeoLocation = {
  latitude: 0,
  longitude: 0
}

export const useUserGeoLocation = (enableAutoRequest = true) => {
  const store = useStoreInstance()
  const geoLocation = useSelector((state: RootState) => state.user.geoLocation)
  const { contentItem = {} } = useContentByNameValue({
    queryKey: GC_GEO_LOCATION_EXPIRED_TIME,
    keys: GC_GEO_LOCATION_EXPIRED_TIME
  })

  const [isLoading, setIsLoading] = useState(!!geoLocation)
  const [error, setError] = useState<GeolocationPositionError | Error>()
  const hasGeoLocationInSession = localStorage.getItem('currentPosition') ?? ''
  const locationTimestampInLocalStorage =
    localStorage.getItem('locationTimestamp') ?? ''
  const isExpired = Number(locationTimestampInLocalStorage) <= Date.now()

  const requestLocation = async () => {
    if ((geoLocation || hasGeoLocationInSession) && !isExpired) {
      return geoLocation || JSON.parse(hasGeoLocationInSession)
    }

    return getUserLocation()
      .then(position => {
        const location = {
          latitude: isNumber(position?.coords?.latitude)
            ? Number(position.coords.latitude.toFixed(2))
            : position?.coords?.latitude,
          longitude: isNumber(position?.coords?.longitude)
            ? Number(position.coords.longitude.toFixed(2))
            : position?.coords?.longitude
        }
        store.dispatch(
          userSave({
            geoLocation: location
          })
        )
        localStorage.setItem('currentPosition', JSON.stringify(location))
        localStorage.setItem(
          'locationTimestamp',
          (
            Date.now() +
              Number(contentItem?.gc_geo_location_expired_time || 0) || ONE_WEEK
          ).toString()
        )

        setIsLoading(false)
        return location
      })
      .catch(err => {
        setIsLoading(false)
        setError(err)
        // ECMD-15313
        const isIphone = /(iPhone)/i.test(navigator.userAgent)
        if (isIphone) {
          sessionStorage.setItem('isNotAllow', 'true')
        }
      })
  }

  useEffect(() => {
    // ECMD-15313
    const isNotAllowLocation = sessionStorage.getItem('isNotAllow')
    if (isNotAllowLocation === 'true') {
      if (isLoading) setIsLoading(false)
      return console.log('geolocation is not permission')
    }
    const currentPositionFromSession = localStorage.getItem('currentPosition')
    if (currentPositionFromSession) {
      const currentPosition = JSON.parse(currentPositionFromSession)
      store.dispatch(
        userSave({
          geoLocation: currentPosition
        })
      )
      if (isLoading) setIsLoading(false)
    }
    if (enableAutoRequest) {
      void requestLocation()
    }
  }, [])

  return {
    geoLocation: geoLocation || defaultGeoLocation,
    isLoading,
    error,
    isUserClickPermission: Boolean(
      (geoLocation?.latitude !== undefined &&
        geoLocation?.latitude !== 0 &&
        geoLocation?.longitude !== undefined &&
        geoLocation?.longitude !== 0) ||
        error
    )
  }
}
